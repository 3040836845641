import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateJobDates } from "../../../../../Models/Jobs/EditJob";
import { EntityCreatedOrUpdatedViewModel, MovexErrorResponse } from "../../../../../Types";

export const usePutUpdateJobCollectionDate = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  MovexErrorResponse,
  UpdateJobDates
> => {
  const putUpdateJobCollectionDate = (payload: UpdateJobDates) => {
    return agent.jobs.putUpdateJobCollectionDate(payload);
  };

  return useMutation(putUpdateJobCollectionDate);
};
