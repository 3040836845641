import { Snackbar, SnackbarCloseReason } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, ConfirmDialog, Loading, ShowPleaseWait, useAlert } from '../../../../../Components';
import { useConfirmDialog } from '../../../../../Components/ConfirmDialog/useConfirmDialog';
import { FormControlKeys } from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
     ColMd6SectionInner,
     FieldSetContainer,
     FieldSetHeader,
     FlexContainer,
     FormFieldSetContainer,
     HoverableLink,
     InlineBlockColMd6Section,
     QuickLinkItem,
} from '../../../../../Components/Formik/StyledComponents';
import { GetAdminRolePermissionModel } from '../../../../../Models/Admin';
import { PermissionType } from '../../../../../Models/Admin/PermissionsModel';
import { AdminRoleModel } from '../../../../../Models/System';
import { RouteConstants } from '../../../../../Routes/Constants';
import withAddForm from '../../../Common/AddForm/withAddForm';
import { AdminIDParamsType } from '../AddAdministrator/Types';
import { useGetAdminPermissions, useGetAdminRole, usePostAddAdminRole, usePutUpdateAdminRole } from '../ViewAdminRoles/Hooks';
import { usePostAddAdminRolePermission } from '../ViewAdminRoles/Hooks/usePostAddAdminRolePermission';
import { usePutUpdateAdminRolePermission } from '../ViewAdminRoles/Hooks/usePutUpdateAdminRolePermission';
import { FormikControlStyled, TabDiv } from './AddAdminRoleStyledComponent';
import ViewUIGridView from './Tabstrip';
import { AddAdminRoleKeys } from './Types';

const AddAdminRole = () => {
     /** Get the current adminId from the page url */
     const { administratorID: paramAdminID } = useParams<AdminIDParamsType>();
     const adminID = paramAdminID ? parseInt(paramAdminID) : 0;
     const isEditMode = adminID > 0;

     /** History Hook for page redirection */
     const history = useHistory();
     const jobsActivityHistoryLink = `${RouteConstants.JobActivityHistoryByAdminID}=${adminID}`;

     /** States Hooks */
     const [openSnackbar, setOpenSnackbar] = useState(false);
     const [snackbarMessage, setSnackbarMessage] = useState('Permission Updated Successfully');
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);

     /** Query Hooks */
     const { data: adminRoleData, isLoading: isAdminRoleLoading } = useGetAdminRole(adminID);
     const { data: adminPermissionsData } = useGetAdminPermissions();
     const {
          mutate: addAdminRole,
          isLoading: isAddAdminRoleLoading,
          error: errorAddAdminRole,
          data: addAdminRoleData,
     } = usePostAddAdminRole();
     const {
          mutate: updateAdminRole,
          isLoading: isUpdateAdminRoleLoading,
          error: errorUpdateAdminRole,
          data: updateAdminRoleData,
     } = usePutUpdateAdminRole();
     const {
          mutate: updateAdminRolePermission,
          error: errorUpdateAdminRolePermission,
          isSuccess: isSuccessUpdateAdminRolePermission,
     } = usePutUpdateAdminRolePermission();

     const { mutate: addAdminRolePermission, error: errorAddAdminRolePermission } = usePostAddAdminRolePermission();

     /** Alert and dialog Hooks */
     const { alert, handleDisplayAlert } = useAlert();
     const { confirmDialogProps } = useConfirmDialog();

     /** Functions */
     const handleSubmit = async () => {
          if (isEditMode) {
               const payload = {
                    Id: adminID,
                    RoleName: formik.values.RoleName,
               } as AdminRoleModel;
               updateAdminRole(payload);
          } else {
               const payload = {
                    RoleName: formik.values.RoleName,
               } as AdminRoleModel;
               addAdminRole(payload);
          }
     };
     const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => {
          if (reason === 'clickaway') {
               return;
          }
          setOpenSnackbar(false);
     };
     const showSnackBar = (message: string) => {
          setOpenSnackbar(true);
          setSnackbarMessage(snackbarMessage);
     };
     interface FormValues {
          RoleName: string;
          AdminRoleCheckboxesPermissions: { [key: string]: boolean };
     }

     /** Formik Hook */
     const [formikInitialValues, setFormikInitialValues] = useState<FormValues>({
          RoleName: '',
          AdminRoleCheckboxesPermissions: {},
     });

     const formik = useFormik({
          initialValues: formikInitialValues,
          onSubmit: handleSubmit,
     });

     /** Set All Checkboxes in our form.
      * Loop through all the permissions and set the values of the checkboxes
      * For each permission, we will have 2 values (because we have 2 checkboxes for each permission):
      * is_XX_allowed: true or false (if the permission is allowed or not)
      * is_XX_denied: true or false (if the permission is denied or not)
      */
     useEffect(() => {
          if (adminPermissionsData && adminRoleData) {
               const retrievedValues = adminPermissionsData.AdminPermissions.reduce<{ [key: string]: boolean }>(
                    (acc, permission) => {
                         var isPermissionAllowed = false;
                         var isPermissionDenied = false;
                         
                         // Overwrite default 'false' permissions for existing role
                         if (isEditMode) {
                              const permissionStatus = adminRoleData.AdminRolePermissions.find(
                                   (item: GetAdminRolePermissionModel) => item.AdminPermissionId === permission.Id
                              );

                              if (permissionStatus) {
                                   isPermissionAllowed = permissionStatus.IsAllowed;
                                   isPermissionDenied = !permissionStatus.IsAllowed;
                              }
                         }

                         acc[`is_${permission.Id}_allowed`] = isPermissionAllowed;
                         acc[`is_${permission.Id}_denied`] = isPermissionDenied;

                         return acc;
                    },
                    {}
               );
               formik.setValues({
                    RoleName: adminRoleData.RoleName || '',
                    AdminRoleCheckboxesPermissions: retrievedValues,
               });
          }
     }, [adminRoleData, adminPermissionsData]);

     useEffect(() => {
          if (isSuccessUpdateAdminRolePermission) {
               showSnackBar('Permission Updated Successfully');
          }
          if (errorUpdateAdminRolePermission) {
               showSnackBar('Permission Updated Failed');
          }
     }, [isSuccessUpdateAdminRolePermission, errorUpdateAdminRolePermission]);

     useEffect(() => {
          if (!isEditMode) {
               formik.setValues({
                    RoleName: '',
                    AdminRoleCheckboxesPermissions: {},
               });
          }
     }, [isEditMode]);

     /** Handle Responses Alerts*/
     useEffect(() => {
          handleDisplayAlert(addAdminRoleData, 'Success', 'Admin role added successfully.');
          handleDisplayAlert(updateAdminRoleData, 'Success', 'Admin Role added successfully.');
          handleDisplayAlert(
               errorUpdateAdminRole,
               'Error',
               errorUpdateAdminRole?.Description ?? 'Error when trying to add Admin role.'
          );
          handleDisplayAlert(
               errorAddAdminRole,
               'Error',
               errorAddAdminRole?.Description ?? 'Error when trying to add Admin Role.'
          );
          handleDisplayAlert(
               errorAddAdminRolePermission,
               'Error',
               errorAddAdminRolePermission?.Description ?? 'Error when trying to add Admin Role Permission.'
          );
          if (addAdminRoleData || updateAdminRoleData) {
               history.push(RouteConstants.ViewAdminRoles);
          }
     }, [addAdminRoleData, updateAdminRoleData, errorUpdateAdminRole, errorAddAdminRole]);

     const component = (
          <FormikProvider value={formik}>
               <Form>
                    <Snackbar
                         open={openSnackbar}
                         autoHideDuration={2000}
                         onClose={handleCloseSnackBar}
                         message={snackbarMessage}
                    />{' '}
                    <Alert {...alert} />
                    <ShowPleaseWait show={showPleaseWait || isUpdateAdminRoleLoading || isAddAdminRoleLoading} />
                    <Loading loading={isLoading} />
                    <ConfirmDialog {...confirmDialogProps} />
                    <FormFieldSetContainer>
                         {isEditMode && (
                              <FieldSetContainer>
                                   <FieldSetHeader>Quick Links</FieldSetHeader>
                                   <QuickLinkItem>
                                        <HoverableLink to={jobsActivityHistoryLink} target='_blank'>
                                             View Jobs Activity History
                                        </HoverableLink>
                                   </QuickLinkItem>
                              </FieldSetContainer>
                         )}
                         <FieldSetContainer>
                              <FlexContainer>
                                   <InlineBlockColMd6Section>
                                        <ColMd6SectionInner>
                                             <FieldSetHeader>Admin Role Details</FieldSetHeader>
                                             <FormikControl name={AddAdminRoleKeys.RoleName} label='Role Name' />
                                             <FormikControlStyled>
                                                  <FormikControl
                                                       name='AddAdminRole'
                                                       control={FormControlKeys.Button}
                                                       type='submit'
                                                       label={isEditMode ? 'Update Admin Role' : 'Add Admin Role'}
                                                  ></FormikControl>
                                             </FormikControlStyled>
                                        </ColMd6SectionInner>
                                   </InlineBlockColMd6Section>
                                   <InlineBlockColMd6Section>
                                        <ColMd6SectionInner>
                                             <FieldSetHeader>Admin Role Permissions</FieldSetHeader>
                                             <Loading loading={isAdminRoleLoading || !(adminRoleData && adminPermissionsData)} />
                                             <TabDiv>
                                                  {adminPermissionsData && adminRoleData && (
                                                       <Tabs defaultActiveKey='ui' id='uncontrolled-tab-example' className='mb-3'>
                                                            <Tab
                                                                 eventKey='ui'
                                                                 title='UI'
                                                            >
                                                                 <ViewUIGridView
                                                                      adminUserId={adminID}
                                                                      isEditMode={isEditMode}
                                                                      permissions={adminPermissionsData.AdminPermissions.filter(
                                                                           (p) => p.PermissionType === PermissionType.UI
                                                                      )}
                                                                      userPermissionsStatus={adminRoleData.AdminRolePermissions}
                                                                      updateAdminRolePermission={updateAdminRolePermission}
                                                                      showSnackBar={showSnackBar}
                                                                      addAdminRolePermission={addAdminRolePermission}
                                                                 />
                                                            </Tab>
                                                            <Tab eventKey='webapi' title='Web-API'>
                                                                 <ViewUIGridView
                                                                      adminUserId={adminID}
                                                                      isEditMode={isEditMode}
                                                                      permissions={adminPermissionsData.AdminPermissions.filter(
                                                                           (p) => p.PermissionType === PermissionType.WebAPI
                                                                      )}
                                                                      userPermissionsStatus={adminRoleData.AdminRolePermissions}
                                                                      updateAdminRolePermission={updateAdminRolePermission}
                                                                      showSnackBar={showSnackBar}
                                                                      addAdminRolePermission={addAdminRolePermission}
                                                                 />
                                                            </Tab>
                                                            <Tab eventKey='tasks' title='Tasks'>
                                                                 <ViewUIGridView
                                                                      adminUserId={adminID}
                                                                      isEditMode={isEditMode}
                                                                      permissions={adminPermissionsData.AdminPermissions.filter(
                                                                           (p) => p.PermissionType === PermissionType.Task
                                                                      )}
                                                                      userPermissionsStatus={adminRoleData.AdminRolePermissions}
                                                                      updateAdminRolePermission={updateAdminRolePermission}
                                                                      addAdminRolePermission={addAdminRolePermission}
                                                                      showSnackBar={showSnackBar}
                                                                 />
                                                            </Tab>
                                                            <Tab
                                                                 eventKey='api'
                                                                 title='API'
                                                            >
                                                                 <ViewUIGridView
                                                                      adminUserId={adminID}
                                                                      isEditMode={isEditMode}
                                                                      permissions={adminPermissionsData.AdminPermissions.filter(
                                                                           (p) => p.PermissionType === PermissionType.API
                                                                      )}
                                                                      userPermissionsStatus={adminRoleData.AdminRolePermissions}
                                                                      updateAdminRolePermission={updateAdminRolePermission}
                                                                      addAdminRolePermission={addAdminRolePermission}
                                                                      showSnackBar={showSnackBar}
                                                                 />
                                                            </Tab>
                                                            <Tab eventKey='operation' title='Operation'>
                                                                 <ViewUIGridView
                                                                      adminUserId={adminID}
                                                                      isEditMode={isEditMode}
                                                                      permissions={adminPermissionsData.AdminPermissions.filter(
                                                                           (p) => p.PermissionType === PermissionType.Operation
                                                                      )}
                                                                      userPermissionsStatus={adminRoleData.AdminRolePermissions}
                                                                      updateAdminRolePermission={updateAdminRolePermission}
                                                                      addAdminRolePermission={addAdminRolePermission}
                                                                      showSnackBar={showSnackBar}
                                                                 />
                                                            </Tab>
                                                       </Tabs>
                                                  )}
                                             </TabDiv>
                                        </ColMd6SectionInner>
                                   </InlineBlockColMd6Section>
                              </FlexContainer>
                         </FieldSetContainer>
                    </FormFieldSetContainer>
               </Form>
          </FormikProvider>
     );
     return withAddForm(component, isEditMode ? 'Update Admin Role' : 'Add Admin Role');
};

export default AddAdminRole;

export interface PermissionObject {
     id: number;
     permission: string;
     permissionGroup: string;
     permissionType: string;
}