import { FormikProvider,useFormik } from 'formik';
import React,{ useEffect,useState } from 'react';
import { Col,Container,Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { BsBoxArrowRight,BsChevronDown } from 'react-icons/bs';
import agent from '../../../../../Axios/Agent';
import {
FormControlKeys,
OptionType
} from '../../../../../Components/FormControls/Types';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import {
FormContainer,
RowFluidContainer
} from '../../../../../Components/Formik/StyledComponents';
import { MembersFilter } from '../../../../../Models/Members';
import { FilterKeys } from '../../../../../Types';
import { MemberType } from '../../../../../Utils/Enums';
import {
  MemberFreeTextSearchBy,
  KeyAccountOptions,
MemberStatusOptions,
MemberTypeOptions,
ServiceTypeOptions
} from '../../Constants';
import { FreeTextSearchByOptions } from '../../../../../Utils/Enums/FreeTextSearchBy';
import { asyncSetAndRefreshValue } from '../../../../../Components/Formik/FormikHelper';

export interface FilterBoxProps {
  filter: MembersFilter;
  onSearch: Function;
  onClearSearch: Function;
  onExportCSV: Function;
  showExport: boolean;
  groupID?: string | null;
}

const FilterBox: React.FC<FilterBoxProps> = (props) => {
  const { onSearch, onClearSearch, onExportCSV, showExport, filter, groupID } = props;

  const initialValues: MembersFilter = {
    FreeText: '',
    FreeTextSearchBy: FreeTextSearchByOptions.Username,
    MemberType: '',
    MemberStatus: '',
    ServiceType: '',
    IsKeyAccount: undefined,
    GroupID: '',
    SignupFromDate: '',
    SignupToDate: '',
  };

  /** useStates */
  const [groupsForDropdown, setGroupsForDropdown] = useState<OptionType[]>([]);

  /** Methods */
  const getGroupsForDropdown = () => {
       agent.groups.getGroupsForDropdown().then((results) => {
            results.unshift({
                 value: '0',
                 label: 'Group Members',
            });
            results.unshift({
                 value: '',
                 label: 'All Members',
            });
            setGroupsForDropdown(results);
       });
  };

  const onSubmit = (values: MembersFilter) => {
       onSearch(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const { handleSubmit, setValues, resetForm, values } = formik;

  const handleClearSearch = () => {
    resetForm();
    onClearSearch();
  };

  /** useEffects */
  useEffect(() => {
    if (groupID) {
      asyncSetAndRefreshValue(formik, FilterKeys.GroupID, groupID);
    }
  }, [groupID]);

  useEffect(() => {
    getGroupsForDropdown();
    
    if (groupID) {
      initialValues.GroupID = groupID;
    }
  }, []);

  useEffect(() => {
    if(filter != null) {
      if(filter.IsKeyAccount == true) setValues({...filter, IsKeyAccount: 1});
      else if(filter.IsKeyAccount == false) setValues({...filter, IsKeyAccount: 0});
      else setValues({...filter, IsKeyAccount: -1});
    }
  }, [filter]);

  const collasibleElement: React.ReactElement = (
    <div>
      <span style={{ marginLeft: '10px' }}>Filter by</span>
      <BsChevronDown style={{ float: 'right', margin: '0 12px' }} />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="well">
          <Collapsible open={true} trigger={collasibleElement}>
            <Container>
              <Row>
                <Col>
                  <FormikControl
                    name={FilterKeys.Freetext}
                    label="Freetext"
                    title="Searches by Member ID, UserName, Email or Company"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.FreeTextSearchBy}
                    label="Freetext Type"
                    control={FormControlKeys.Select}
                    options={MemberFreeTextSearchBy}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.MemberType}
                    label="Member Type"
                    control={FormControlKeys.Select}
                    options={MemberTypeOptions}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.MemberStatus}
                    label="Member Status"
                    control={FormControlKeys.Select}
                    options={MemberStatusOptions}
                  />
                </Col>
                {values.MemberType &&
                  parseInt(values.MemberType) ===
                    MemberType.TransportCompany && (
                    <Col>
                      <FormikControl
                        name={FilterKeys.ServiceType}
                        label="Service Type"
                        control={FormControlKeys.Select}
                        options={ServiceTypeOptions}
                      />
                    </Col>
                  )}
                <Col>
                  <FormikControl
                    name={FilterKeys.IsKeyAccount}
                    label="Key Account"
                    control={FormControlKeys.RadioGroup}
                    options={KeyAccountOptions}
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.GroupID}
                    label="Group"
                    control={FormControlKeys.Select}
                    options={groupsForDropdown}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikControl
                    name={FilterKeys.SignupFromDate}
                    label="Signup From date"
                    control={FormControlKeys.DateInput}
                    type="date"
                    title="Date Added To"
                  />
                </Col>
                <Col>
                  <FormikControl
                    name={FilterKeys.SignupToDate}
                    label="Signup To date"
                    control={FormControlKeys.DateInput}
                    type="date"
                    min={values.SignupFromDate}
                  />
                </Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <Row>
                <br />
              </Row>
              <RowFluidContainer>
                <Col>
                  <div>
                    <FormikControl
                      name="Search"
                      label="Search"
                      type="submit"
                      className="btn btn-light span2"
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                    <FormikControl
                      name="Clear"
                      label="Clear"
                      className="btn btn-light span2"
                      onClick={handleClearSearch}
                      control={FormControlKeys.Button}
                    />
                    <span style={{ marginRight: 15 }}></span>
                    {showExport && (
                      <FormikControl
                        name="ExportCsv"
                        label="Export To CSV"
                        className="btn btn-light span2"
                        onClick={() => {
                          onExportCSV();
                          }}
                        control={FormControlKeys.ButtonGroup}
                        icon={<BsBoxArrowRight />}
                      />
                    )}
                  </div>
                </Col>
                <Col></Col>
              </RowFluidContainer>
            </Container>
          </Collapsible>
        </div>
      </FormContainer>
    </FormikProvider>
  );
};

export default FilterBox;
