import { useFormikContext } from 'formik';
import Table from 'react-bootstrap/Table';
import { FormControlKeys } from '../../../../../../Components/FormControls';
import FormikControl from '../../../../../../Components/Formik/FormikControl';
import { PermissionModel } from '../../../../../../Models/Admin/PermissionsModel';
import { GetAdminRolePermissionModel } from '../../../../../../Models/Admin';

interface Props {
     adminUserId: number;
     isEditMode: boolean;
     permissions: PermissionModel[];
     userPermissionsStatus: GetAdminRolePermissionModel[];
     updateAdminRolePermission: Function;
     addAdminRolePermission: Function;
     showSnackBar: Function;
}
const ViewUIGridView: React.FC<Props> = ({ permissions, userPermissionsStatus, updateAdminRolePermission, 
                                           addAdminRolePermission, adminUserId, isEditMode }) => {
     // Sort by group and then alphabetically within each group.
     const sortedList = permissions.sort((a: PermissionModel, b: PermissionModel) => {
          if (a.PermissionGroup === b.PermissionGroup) {
               return a.Permission.localeCompare(b.Permission);
          }
          return a.PermissionGroup.localeCompare(b.PermissionGroup);
     });

     // will be used to display the group name before each new group in the render method.
     let currentGroup: string | null = null;

     /** Get the formik context from the parent component */
     const formik = useFormikContext();

     return (
          <form>
               <Table id='id_table_admin_permissions' striped bordered hover>
                    <thead>
                         <tr>
                              <th>Permission</th>
                              <th>Allowed</th>
                              <th>Denied</th>
                         </tr>
                    </thead>
                    <tbody>
                         {sortedList.map((permission, index) => {
                              const isNewGroup = currentGroup !== permission.PermissionGroup;
                              if (isNewGroup) {
                                   currentGroup = permission.PermissionGroup;
                              }
                              return (
                                   <>
                                        {isNewGroup && (
                                             <tr>
                                                  <td colSpan={3}>
                                                       <b>{currentGroup}</b>
                                                  </td>
                                             </tr>
                                        )}
                                        <tr key={permission.Id + index}>
                                             <td>{removeAdminUsers(permission.Permission)}</td>
                                             <td>
                                                  <FormikControl
                                                       name={`AdminRoleCheckboxesPermissions.is_${permission.Id}_allowed`}
                                                       disabled={!isEditMode} 
                                                       label=''
                                                       control={FormControlKeys.Checkbox}
                                                       onClick={(e: any) => {
                                                            const foundPermission = userPermissionsStatus.find(
                                                                 (item: GetAdminRolePermissionModel) => item.AdminPermissionId === permission.Id
                                                            );
                                                            if(foundPermission === undefined){
                                                                 addAdminRolePermission({
                                                                      IsAllowed: e.target.checked,
                                                                      AdminRoleId: adminUserId,
                                                                      AdminPermissionId: permission.Id,
                                                                      Id: null,
                                                                 });
                                                            }
                                                            else{
                                                                 updateAdminRolePermission({
                                                                      IsAllowed: e.target.checked,
                                                                      AdminRoleId: adminUserId,
                                                                      AdminPermissionId: permission.Id,
                                                                      Id: foundPermission.Id,
                                                                 });
                                                            }
                                                            /**We need to update the other checkbox as well: */
                                                            if (e.target.checked) {
                                                                 formik.setFieldValue(
                                                                      `AdminRoleCheckboxesPermissions.is_${permission.Id}_denied`,
                                                                      false
                                                                 );
                                                            }
                                                       }}
                                                  />
                                             </td>
                                             <td>
                                                  <FormikControl
                                                       name={`AdminRoleCheckboxesPermissions.is_${permission.Id}_denied`}
                                                       disabled={!isEditMode} 
                                                       label=''
                                                       control={FormControlKeys.Checkbox}
                                                       onClick={(e: any) => {
                                                            updateAdminRolePermission({
                                                                 IsAllowed: !e.target.checked,
                                                                 AdminRoleId: userPermissionsStatus.find(
                                                                      (x) => x.AdminPermissionId === permission.Id
                                                                 )?.AdminRoleId,
                                                                 AdminPermissionId: permission.Id,
                                                                 Id: userPermissionsStatus.find(
                                                                      (x) => x.AdminPermissionId === permission.Id
                                                                 )?.Id,
                                                            });
                                                            /**We need to update the other checkbox as well: */
                                                            if (e.target.checked) {
                                                                 formik.setFieldValue(
                                                                      `AdminRoleCheckboxesPermissions.is_${permission.Id}_allowed`,
                                                                      false
                                                                 );
                                                            }
                                                       }}
                                                  />
                                             </td>
                                        </tr>
                                   </>
                              );
                         })}
                    </tbody>
               </Table>
          </form>
     );
};

export default ViewUIGridView;


function removeAdminUsers(input: string): string {
     return input.replace('AdminUsers|', '').replace('Admin|', '');
}
