import axios from 'axios';
import { ApiURLConstants } from '../../ApiUrlConstants';
import { formDataConfig, jsonConfig, responseBody } from "../../Agent";
import { DeleteQuoteModel, MemberSearchFilter, PreviousSupplierSearchFilter, ReviveJobModel, ReviveJobResponseModel } from '../../../Models/Jobs/EditJob';
import { JobsRequestParam, SavedSearch } from '../../../Models/Jobs';
import { IGetQuoteFeeDetailsRequest } from '../../../Models/Jobs/EditJob/GetQuoteFeeDetailsRequest';
import { ReOfferJobResponseModel } from '../../../Models/Jobs/EditJob/ReOfferJobResponseModel';
import { RelistJobRequest } from '../../../Models/Jobs/EditJob/RelistJobRequest';
import { IUnlockReason } from '../../../Models/Jobs/EditJob/IUnlockReason';
import { AbortJobRequest } from '../../../Models/Jobs/EditJob/AbortJobRequest';
import { UnassignSupplyingMemberRequest } from '../../../Models/Jobs/EditJob/UnassignSupplyingMemberRequest';
import { UpdateQuoteMarginRequest } from '../../../Models/Jobs/EditJob/UpdateQuoteMarginRequest';

const {
  DeleteQuote_URL,
  GetAdminAccountInformation_URL,
  GetAdminUsersForDropdown_URL,
  GetCanRelistJob_URL,
  GetJob_URL,
  GetJobs_URL,
  GetMarginForQuote_URL,
  GetPostAdminAbortMotabilityJob_URL,
  GetPreviousSupplyingMembersByRange_URL,
  GetQuoteFeeDetails_URL,
  GetReleaseNotes_URL,
  GetSupplyingMembersNearby_URL,
  PostAddQuote_URL,
  PutAcceptQuote_URL,
  PutRelistJob_URL,
  PutReOfferJob_URL,
  PutReviveJob_URL,
  PutUnassignSupplyingMember_URL,
  PutUnlockJob_URL,
  PutUpdateAdditionalNotes_URL,
  PutUpdateAdminUserData_URL,
  PutUpdateDeliveryContact_URL,
  PutUpdateDeliveryDate_URL,
  PutUpdateCollectionDate_URL,
  PutUpdateJobAdminNotes_URL,
  PutUpdateJobAdminUser_URL,
  PutUpdateJobFixedPrices_URL,
  PutUpdateJobGuaranteedPrices_URL,
  PutUpdateJobReference_URL,
  PutUpdateJobRequestMoreQuotes_URL,
  PutUpdateJobStatus_URL,
  PutUpdateQuoteMargin_URL,
  UpdateProofStatus_URL,
  UploadNewProof_URL,
} = ApiURLConstants;

export const jobs = {
  getJobs: (params: JobsRequestParam): Promise<any> => {
    return axios.post(`${GetJobs_URL}`, params, jsonConfig)
      .then(responseBody);
  },

  getJob: (jobID: any): Promise<any> => {
    return axios
      .get(`${GetJob_URL}?jobID=${jobID}`, jsonConfig)
      .then(responseBody);
  },

  getAdminSavedSearch: (): Promise<SavedSearch[]> => {
    return axios
      .get(GetAdminAccountInformation_URL, jsonConfig)
      .then(responseBody);
  },

  getAdminAccountInformation: (): Promise<SavedSearch[]> => {
    return axios
      .get(GetAdminAccountInformation_URL, jsonConfig)
      .then(responseBody)
      .then((result => JSON.parse(result?.UserData)));
  },

  putUpdateJobStatus: (params: any): Promise<any> => {
    return axios
      .put(`${PutUpdateJobStatus_URL}`, params, jsonConfig)
      .then(responseBody);
  },

  putReviveJob: (params: ReviveJobModel): Promise<ReviveJobResponseModel> => {
    return axios
      .put(PutReviveJob_URL, params, jsonConfig)
      .then(responseBody);
  },

  getAdminUsersForDropdown: (): Promise<any> => {
    return axios.get(GetAdminUsersForDropdown_URL).then(responseBody);
  },

  putUpdateAdminUserData: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateAdminUserData_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUnlockJob: (params: IUnlockReason): Promise<any> => {
    return axios
      .put(PutUnlockJob_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateJobReference: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateJobReference_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateJobFixedPrices: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateJobFixedPrices_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateJobDeliveryContact: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateDeliveryContact_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateJobDeliveryDate: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateDeliveryDate_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateJobCollectionDate: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateCollectionDate_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateAdditionalNotes: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateAdditionalNotes_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateJobGuaranteedPrices: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateJobGuaranteedPrices_URL, params, jsonConfig)
      .then(responseBody);
  },

  getGetPreviousSupplyingMembersByRange: (params: PreviousSupplierSearchFilter): Promise<any> => {
    return axios
      .get(`${GetPreviousSupplyingMembersByRange_URL}?JobId=${params.JobID}&PreviouslyCompletedRoute=${params.PreviouslyCompletedRoute}&CollectionRadius=${params.CollectionRadius}&DeliveryRadius=${params.DeliveryRadius}`)
      .then(responseBody);
  },

  getSupplyingMembersNearby: (params: MemberSearchFilter): Promise<any> => {
    return axios
      .get(`${GetSupplyingMembersNearby_URL}?JobId=${params.JobId}&WithinMiles=${params.WithinMiles}&PreviouslyCompletedRoute=${params.PreviouslyCompletedRoute}`)
      .then(responseBody);
  },

  putUpdateJobRequestMoreQuotes: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateJobRequestMoreQuotes_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUploadNewProof: (params: any): Promise<any> => {
    return axios
      .put(UploadNewProof_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUploadProofStatus: (params: any): Promise<any> => {
    return axios
      .put(UpdateProofStatus_URL, params, jsonConfig)
      .then(responseBody);
  },

  postAddQuote: (params: any): Promise<any> => {
    return axios
      .post(PostAddQuote_URL, params, jsonConfig)
      .then(responseBody);
  },

  getQuoteFeeDetails: (params: IGetQuoteFeeDetailsRequest): Promise<any> => {
    return axios
      .get(`${GetQuoteFeeDetails_URL}?MemberId=${params.memberId}&QuoteAmount=${params.quoteAmount}`)
      .then(responseBody);
  },

  putGetMarginForQuote: (params: any): Promise<any> => {
    return axios
      .put(`${GetMarginForQuote_URL}?jobID=${params.jobID}&quoteType=${params.quoteType}&quoteAmount=${params.quoteAmount}`, jsonConfig)
      .then(response => response.data);
  },

  deleteQuote: (params: DeleteQuoteModel): Promise<any> => {
    var bodyFormData = new FormData();
    bodyFormData.append('quoteID', params.quoteID.toString());
    return axios.put(`${DeleteQuote_URL}?quoteID=${params.quoteID}`, bodyFormData, formDataConfig)
      .then(responseBody)
  },

  putAcceptQuote: (params: any): Promise<any> => {
    return axios
      .put(PutAcceptQuote_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateQuoteMargin: (params: UpdateQuoteMarginRequest): Promise<any> => {
    return axios
      .put(PutUpdateQuoteMargin_URL, params, jsonConfig)
      .then(responseBody);
  },

  putRelistJob: (params: RelistJobRequest): Promise<any> => {
    return axios
      .put(PutRelistJob_URL, params, jsonConfig)
      .then(response => response.data);
  },

  putReOfferJob: (params: number): Promise<ReOfferJobResponseModel> => {
    return axios
      .put(`${PutReOfferJob_URL}?jobId=${params}`, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateJobAdminNotes: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateJobAdminNotes_URL, params, jsonConfig)
      .then(responseBody);
  },

  putUpdateJobAdminUser: (params: any): Promise<any> => {
    return axios
      .put(PutUpdateJobAdminUser_URL, params, jsonConfig)
      .then(responseBody);
  },

  getReleaseNotes: (jobID: number): Promise<any> => {
    return axios
      .get(`${GetReleaseNotes_URL}?jobID=${jobID}`, jsonConfig)
      .then(responseBody);
  },

  GetCanRelistJob: (jobId: number): Promise<any> => {
    return axios
      .get(`${GetCanRelistJob_URL}?jobId=${jobId}`, jsonConfig)
      .then(response => response.data);
  },

  postAdminAbortMotabilityJob: (request: AbortJobRequest): Promise<any> => {
    return axios
      .post(GetPostAdminAbortMotabilityJob_URL, request, jsonConfig)
      .then(responseBody);
  },

  putUnassignSupplyingMember: async (request: UnassignSupplyingMemberRequest): Promise<any> => {
    const response = await axios
      .put(PutUnassignSupplyingMember_URL, request, jsonConfig);

    return responseBody(response);
  },
};
