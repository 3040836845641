import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import MovexResponseError from '../../../../Axios/MovexResponseError';
import {
  Alert,
  Loading,
  ShowPleaseWait,
  useAlert,
} from '../../../../Components';
import { Button } from '../../../../Components/FormControls';
import { FormControlKeys } from '../../../../Components/FormControls/Types';
import FormikControl from '../../../../Components/Formik/FormikControl';
import {
  DivContainer,
  FieldSetContainer,
  FieldSetHeader,
  FlexContainer,
  FormContainer,
  HoverableLink,
  Margin20RightSpan,
  QuickLinkItem,
  Width50DivContainer,
} from '../../../../Components/Formik/StyledComponents';
import { AddPartnerMemberModel } from '../../../../Models/Members/EditMember/MemberPartners';
import {
  addPartnerExampleForm,
  addPartnerExamplesUrL,
  AddPartnerModel,
  DeletePartnerMembersRequestModel,
  PartnerMember,
  UpdatePartnerRequestModel
} from '../../../../Models/Partners';
import { RouteConstants } from '../../../../Routes';
import { convertNullsToEmptyStrings, emailValidator, getDropdownMemberOptions, isMovexErrorResponse, isSuccessEntityCreatedOrUpdatedResponse } from '../../../../Utils';
import withAddForm from '../../Common/AddForm/withAddForm';
import { useGetMembersForDropdown } from '../../Members/EditMember/Tabstrip/MemberBlocks/Hooks';
import { AddPartnerKeys, AddPartnerMemberKeys, PartnerIDParamType } from '../ViewPartners/Types';
import {
  useDeleteSelectedMembersFromPartner,
  useGetPartner,
  usePostAddPartner,
  usePostAddSelectedMemberToPartner,
  usePutPartner,
} from './Hooks';

const columnsForPartnerMembers: GridColDef[] = [
  {
    field: AddPartnerMemberKeys.UserName,
    headerName: 'User Name',
    flex: 1,
  },
  {
    field: AddPartnerMemberKeys.Company,
    headerName: 'Company',
    flex: 1,
  },
  {
    field: AddPartnerMemberKeys.PartnerCID,
    headerName: 'Partner CID',
    flex: 1,
  },
];

const validationSchema = object({
  [AddPartnerKeys.Email]: string().required().test(AddPartnerKeys.Email, 'Please enter a valid email address', emailValidator),
  [AddPartnerKeys.Name]: string().required(),
});


const AddPartner: React.FC = (): JSX.Element => {
  
  const history = useHistory();
  const {partnerID : paramPartnerID } = useParams<PartnerIDParamType>();
  const partnerID = paramPartnerID ? parseInt(paramPartnerID) : 0;
  const isEditMode = partnerID > 0;

  const jobsLink = `${RouteConstants.ViewJobs}`;

  const addPartnerFormInitialValues : AddPartnerModel = {
    PartnerID: 0,
    PartnerCID: '',
    MemberID: 0,
    Email: '',
    EncryptionKey: '',
    EncryptionVector: '',
    Id:0,
    IsActive: false,
    Name: '',
    SupportEmail: '',
    ExampleUrl : addPartnerExamplesUrL(),
    ExampleForm : addPartnerExampleForm(), 
  }

  /** useStates */
  const [formikInitialValues, setFormikInitialValues] = useState<AddPartnerModel>(addPartnerFormInitialValues);
  const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [membersForGridView, setMembersForGridView] = useState<PartnerMember[]>([]);
  const [selectedPartnerMemberIds, setSelectedPartnerMemberIds] =
    React.useState<number[]>([]);

  /** Custom Hook */
  const {alert, setAlert, handleAlertClose } = useAlert();

  /** Methods */
  const onSubmit = (payload: any): void => {
    const request: AddPartnerModel = { ...payload };
    if (isEditMode) {
      const editRequest: UpdatePartnerRequestModel = {...payload };
      putUpdatePartner(editRequest);
    } else {
      postAddPartner(request);
    }
  };

  const CustomFooterForGrid = (props: any) => {
    return (
      <Button
        className="btn btn-danger btn-sm"
        label="Delete"
        disabled={!selectedPartnerMemberIds.length}
        style={{ margin: 10 }}
        onClick={removeSelectedMembersFromPartner}
      ></Button>
    );
  };

  const removeSelectedMembersFromPartner = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const payload: DeletePartnerMembersRequestModel = {
      PartnersMembersIds: selectedPartnerMemberIds,
      PartnerID: partnerID,
    };
    deleteSelectedMembersFromPartner(payload);
  };

  const addMemberToPartner = (partnerID?: number, partnerCID?: string, memberId?: number ) => {
    const payload: AddPartnerMemberModel = {
      PartnerID: partnerID ? partnerID : 0,
      PartnerCID: partnerCID ? partnerCID : '',
      MemberID: memberId ? memberId : 0
    };
    postAddMemberToPartner(payload);
  };
  
  const onPartnerSaveSuccess = () => {
    history.push(RouteConstants.ViewPartners);
  };

  const onPartnerUpdateSuccess = () => {
    history.push(RouteConstants.ViewPartners);
  };

  const onMemberPartnerAddedSuccess = () => {
    refetch();
    handleAlertClose();
  }

  /** React Query Hooks */
  const { 
    data: partnerMembers = [],
    error: getPartnerMembersError,
   } = useGetMembersForDropdown();

  const {
    mutate: deleteSelectedMembersFromPartner,
    data: deleteSelectdMemberPartner,
    isLoading: isDeleteSelectedMembersFromPartnerLoading,
    error: deleteSelectedMembersFromPartnerError,
  } = useDeleteSelectedMembersFromPartner();

  const {
    mutate: postAddMemberToPartner,
    data: addSelectedMemberToPartner,
    isLoading: isPostAddSelectedMemberToPartnerLoading,
    error: postAddMemberToPartnerError
  } = usePostAddSelectedMemberToPartner();

  const {
    mutate: putUpdatePartner,
    isLoading: isPutUpdatePartnerLoading,
    data: updatePartnerResponse,
    error: updatePartnerError,
  } = usePutPartner();

  const {
    mutate: postAddPartner,
    isLoading: isPostAddPartnerLoading,
    data: addPartnerResponse,
    error: addPartnerError,
  } = usePostAddPartner();

  const { 
    isLoading: isGetPartnerListLoading, 
    data: getPartnerResponse, 
    refetch,
    error: getPartnersListError 
  } = useGetPartner(partnerID);

  /** useEffects */
  useEffect(() => {
    if (getPartnerResponse && getPartnerResponse.Members) {
      setMembersForGridView(getPartnerResponse.Members);
    }else{
      setMembersForGridView([]);
    }
  }, [getPartnerResponse]);

  useEffect(() => {
    if (getPartnerResponse) {
      const partner = getPartnerResponse;
      convertNullsToEmptyStrings(partner);
      setFormikInitialValues({
        ...formikInitialValues,
        ...partner
      });
    }else{
      setFormikInitialValues(addPartnerFormInitialValues);
    }
  }, [getPartnerResponse]);

  useEffect(() => {
    setIsLoading(isGetPartnerListLoading);
  }, [isGetPartnerListLoading]);

  useEffect(() => {
    setShowPleaseWait(isPostAddPartnerLoading || isPutUpdatePartnerLoading || isPostAddSelectedMemberToPartnerLoading || isDeleteSelectedMembersFromPartnerLoading);
  }, [isPostAddPartnerLoading, isPutUpdatePartnerLoading, isPostAddSelectedMemberToPartnerLoading, isDeleteSelectedMembersFromPartnerLoading]);

  useEffect(() => {
    if ( postAddMemberToPartnerError 
      || deleteSelectedMembersFromPartnerError ) {
      if (
        isMovexErrorResponse(postAddMemberToPartnerError)
        || isMovexErrorResponse(deleteSelectedMembersFromPartnerError)
      ) {
        setAlert({
          ...alert,
          show: true,
          body: postAddMemberToPartnerError?.Description 
                ?? deleteSelectedMembersFromPartnerError?.Description 
                ?? "Member not found!"
        });
      }
    }
  }, [
    postAddMemberToPartnerError, 
    deleteSelectedMembersFromPartnerError
  ]);

  useEffect(() => {
    if (addPartnerResponse) {
      setAlert({
        ...alert,
        show: true,
        body: 'Save Successful!',
        closeCallback: onPartnerSaveSuccess,
      });
    }
  }, [addPartnerResponse]);

  useEffect(() => {
    if (updatePartnerResponse) {
      setAlert({
        ...alert,
        show: true,
        body: 'Update Successful!',
        closeCallback: onPartnerUpdateSuccess,
      });
    }
  }, [updatePartnerResponse]);

  useEffect(() => {
    if (addSelectedMemberToPartner) {
      const partnerMember = partnerMembers.find(x => x.Id.toString() === values.MemberID.toString());
      setAlert({
        ...alert,
        show: true,
        header: 'Addition Successful!',
        body: '\'' + partnerMember?.UserName + "\' successfully added as a partner member for \'" + values.Name + "\' with CID \'" + values.PartnerCID + "\'",
        closeCallback: onMemberPartnerAddedSuccess,
      });
    }
  }, [addSelectedMemberToPartner]);

  useEffect(() => {
    if (deleteSelectdMemberPartner) {
      setAlert({
        ...alert,
        show: true,
        header: 'Delete Successful!',
        body: (selectedPartnerMemberIds.length > 1)
        ?  selectedPartnerMemberIds.length + " partner members of \'" + values.Name + "\' were successfully deleted." 
        : selectedPartnerMemberIds.length + " partner member of \'" + values.Name + "\' was successfully deleted.",
        closeCallback: onMemberPartnerAddedSuccess,
      });
    }
  }, [deleteSelectdMemberPartner]);

  useEffect(() => {
    if (addPartnerError) {
      if (isMovexErrorResponse(addPartnerError)) {
        setAlert({
          ...alert,
          show: true,
          header: MovexResponseError.getMovexErrorContent(addPartnerError).header,
          body: MovexResponseError.getMovexErrorContent(addPartnerError).body,
        });
      }
    }
  }, [addPartnerError]);

  useEffect(() => {
    if (updatePartnerError) {
      if (isMovexErrorResponse(updatePartnerError)) {
        setAlert({
          ...alert,
          show: true,
          header: MovexResponseError.getMovexErrorContent(updatePartnerError).header,
          body: MovexResponseError.getMovexErrorContent(updatePartnerError).body,
        });
      }
    }
  }, [updatePartnerError]);

  useEffect(() => {
    if (getPartnersListError) {
      setAlert({
        ...alert,
        show: true,
        header: getPartnersListError?.Subject,
        body: getPartnersListError?.Description,
      });
    } else if (getPartnerMembersError) {
      setAlert({
        ...alert,
        show: true,
        header: getPartnerMembersError?.Subject,
        body: getPartnerMembersError?.Description,
      });
    }
  }, [getPartnersListError, getPartnerMembersError]);

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, values } = formik;
  const component = (
    <FormikProvider value={formik}>
      <Alert {...alert} />
      <ShowPleaseWait show={showPleaseWait} />
      <Loading loading={isLoading} />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >

        <FieldSetContainer>
            <FieldSetHeader>Quick Links</FieldSetHeader>
              <QuickLinkItem>
                <HoverableLink to={jobsLink} target="_blank">
                  View Jobs
                </HoverableLink>
              </QuickLinkItem>
          </FieldSetContainer>
        <FieldSetContainer>

        <FlexContainer>
          <Width50DivContainer>
            <FieldSetContainer>
              <FieldSetHeader>Partner Details</FieldSetHeader>
              <DivContainer>
                <FormikControl name={AddPartnerKeys.Name} label="Name" />
                <FormikControl name={AddPartnerKeys.Email} label="Email" />
                <FormikControl
                  name={AddPartnerKeys.SupportEmail}
                  label="Support Email"
                />
                <FormikControl
                  name={AddPartnerKeys.SuccessCallBackURL}
                  label="Success CallBack URL"
                />
                <FormikControl
                  name={AddPartnerKeys.ErrorCallBackURL}
                  label="Error CallBack URL"
                />
                <FormikControl
                  name={AddPartnerKeys.EncryptionKey}
                  label="Encryption Key"
                  readOnly="readOnly"
                />
                <FormikControl
                  name={AddPartnerKeys.EncryptionVector}
                  label="Encryption Vector"
                  readOnly="readOnly"
                />
                <FormikControl
                  control={FormControlKeys.Checkbox}
                  name={AddPartnerKeys.IsActive}
                  label="Is Active"
                />
                <FormikControl
                  name={AddPartnerKeys.ExampleUrl}
                  label="Example Url (for GET):"
                  readOnly="readOnly"
                />
                <FormikControl
                  name={AddPartnerKeys.ExampleForm}
                  label="Example Url (for POST):"
                  readOnly="readOnly"
                />
                <FormikControl
                  name="AddPartner"
                  control={FormControlKeys.Button}
                  type="submit"
                  label={partnerID ? 'Update Partner' : 'Add Partner'}
                />
              </DivContainer>
            </FieldSetContainer>
          </Width50DivContainer>
          <Width50DivContainer>
            <FieldSetContainer>
              <FieldSetHeader>Partner Members</FieldSetHeader>
              <DivContainer>
                <FlexContainer>
                  <FormikControl
                    name={AddPartnerMemberKeys.MemberID}
                    control={FormControlKeys.Select}
                    label="Add a Member to the Partner:"
                    placeholder="Select Member"
                    className="member-select form-select"
                    options={getDropdownMemberOptions(partnerMembers)}
                  />
                  <Margin20RightSpan />
                  <FormikControl
                    name={AddPartnerMemberKeys.PartnerCID}
                    placeholder="Partner CID"
                    label="Partner CID"
                  />
                </FlexContainer>
                <DivContainer>
                  <FormikControl
                    control={FormControlKeys.Button}
                    label="Add Member"
                    disabled={!values?.MemberID}
                    onClick={() =>
                      addMemberToPartner(values?.Id, values.PartnerCID, values?.MemberID)
                    }
                  />
                </DivContainer>
                <DivContainer>
                  <h6 style={{ fontWeight: 'bold', marginTop: 20 }}>
                    Administer Current Members of the Partner:
                  </h6>
                  <div id='id_grid_members_of_the_partner' style={{ width: '100%' }}>
                    <DataGrid
                      sortingOrder={['desc', 'asc']}
                      getRowId={(row: any) => row.Id}
                      autoHeight
                      rows={membersForGridView}
                      disableColumnMenu={true}
                      columns={columnsForPartnerMembers}
                      hideFooterPagination={true}
                      checkboxSelection
                      disableSelectionOnClick
                      selectionModel={selectedPartnerMemberIds}
                      onSelectionModelChange={(selected) => {
                        setSelectedPartnerMemberIds(selected as number[]);
                      }}
                      components={{
                        Footer: CustomFooterForGrid,
                      }}
                    />
                  </div>
                </DivContainer>
              </DivContainer>
            </FieldSetContainer>
          </Width50DivContainer>
        </FlexContainer>
        </FieldSetContainer>
      </FormContainer>
    </FormikProvider>
  );
  return withAddForm(component, isEditMode ? 'Edit Partner' : 'Add Partner');
};

export default AddPartner;
