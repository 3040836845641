import { useMutation, UseMutationResult } from "react-query";
import agent from "../../../../../Axios/Agent";
import { UpdateJobDates } from "../../../../../Models/Jobs/EditJob";
import { EntityCreatedOrUpdatedViewModel, MovexErrorResponse } from "../../../../../Types";

export const usePutUpdateJobDeliveryDate = (
  options?: any
): UseMutationResult<
  EntityCreatedOrUpdatedViewModel,
  MovexErrorResponse,
  UpdateJobDates
> => {
  const putUpdateJobDeliveryDate = (payload: UpdateJobDates) => {
    return agent.jobs.putUpdateJobDeliveryDate(payload);
  };

  return useMutation(putUpdateJobDeliveryDate);
};
