import { DataGrid, GridRowParams, GridSelectionModel, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ModalDialog, ModalDialogProps, useAlert, Alert } from '../../../../../Components';
import { Button, FormControlKeys } from '../../../../../Components/FormControls';
import { ControlledPagination as Pagination } from '../../../../../Components/Pagination/Pagination';
import { AdminUserDataModel, UserData } from '../../../../../Models/Admin';
import { DropdownAdminUsers } from '../../../../../Models/Jobs/EditJob';
import { JobItemModel, JobsFilterFormModel, JobsResponseModel, SavedSearch, SearchData } from '../../../../../Models/Jobs/ViewJobs';
import { Pager, Sorter } from '../../../../../Types';
import { usePutUpdateJobAdminUser } from '../../EditJob/Hooks';
import { useGetAdminUsersForDropdown } from '../../EditJob/Hooks/useGetAdminUsersForDropdown';
import { getJobFiltersFromSavedSearchModel } from '../helper';
import { usePutUpdateAdminUserData } from '../Hooks';
import SaveJobSearchModule from '../Module/SaveJobSearchModule';
import { getColumns } from './Columns';
import { SaveAssignJobFooter } from './SaveAssignJobFooter';
import Filter from '../FilterBox/Filter';

interface GridProps {
     data: JobsResponseModel;
     savedSearches: SavedSearch[];
     selectedQuickSearch: string;
     clearSearch: () => void;
     handleSetFilter: (filter: JobsFilterFormModel, selectedQuickSearch?: string) => void;
     handleSetPager: (pager: Pager) => void;
     handleSetSorter: (sorter: Sorter) => void;
     onPageChange: (currentPage: number) => void;
     onPageSizeChange: (pageSize: number) => void;
     onSort: (sorter: GridSortModel) => void;
     performQuickSearch: (searchTerm: string) => void;
     showFilter: () => void;
}

export default function GridView({
     data,
     savedSearches = [],
     selectedQuickSearch,
     clearSearch,
     handleSetFilter,
     handleSetPager,
     handleSetSorter,
     onPageChange,
     onPageSizeChange,
     onSort,
     performQuickSearch,
     showFilter,
}: GridProps) {
     const [selectedJobs, setSelectedJobs] = useState<GridSelectionModel>([]);

     /** Custom Hook */
     const { alert, setAlert } = useAlert();

     /** Query Hook */
     const { data: adminUsersForDropdown, } = useGetAdminUsersForDropdown();

     const {
          mutate: putUpdateJobsAdminUser,
          data: putUpdateJobsAdminUserResponse,
          error: putUpdateJobsAdminUserError,
     } = usePutUpdateJobAdminUser();

     /** useEffect */
     useEffect(() => {
          if (putUpdateJobsAdminUserResponse) {
               setModalContent({
                    ...modalContent,
                    show: true,
                    header: 'Job Assigned Successfully',
                    body: putUpdateJobsAdminUserResponse + ' Job(s)' + selectedJobs + ' assigned successfully.',
                    footer: <SaveJobFooter onClose={handleModalClose} />,
               });
          }
     }, [putUpdateJobsAdminUserResponse]);

     useEffect(() => {
          if (putUpdateJobsAdminUserError) {
               setModalContent({
                    ...modalContent,
                    show: true,
                    header: putUpdateJobsAdminUserError?.Subject,
                    body: putUpdateJobsAdminUserError?.Description,
                    footer: <SaveJobFooter onClose={handleModalClose} />,
               });
          }
     }, [putUpdateJobsAdminUserError]);

     const handleModalClose = () => {
          setModalContent({ ...modalContent, show: false });
          setSelectedJobs([]);
     };

     const getRowClassName = useCallback((params: GridRowParams) => {
          let className = '';

          if (params.row.MaximumMargin) {
               className = 'has-max-margin'
          }

          if (params.row.HasQuoteWithinFixedPriceRange) {
               className = 'has-quote-within-fixed-price-range';
          }

          return className;
     }, []);

     const [modalContent, setModalContent] = useState<ModalDialogProps>({
          header: '',
          body: '',
          show: false,
          closeCallback: handleModalClose,
     });

     const handlePageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          currentPage: number
     ) => {
          onPageChange(currentPage);
     };

     const handleRowsPerPageChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
     ) => {
          onPageSizeChange(parseInt(e.target.value));
     };
     const handleSort = (sortModel: GridSortModel) => {
          onSort(sortModel);
     };

     const assignJobsToUser = (user: DropdownAdminUsers) => {
          if (selectedJobs?.length === 0) {
               setModalContent({
                    ...modalContent,
                    show: true,
                    header: 'Please Select Job(s)',
                    body: 'Please select at least one job to assign.',
                    footer: <SaveJobFooter onClose={handleModalClose} />,
               });
               return null;
          }
          setModalContent({
               ...modalContent,
               show: true,
               header: 'Assign job(s) to ' + user.DisplayName + '?',
               body: 'Are you Sure?',
               footer: (
                    <SaveAssignJobFooter
                         user={user}
                         jobsIds={selectedJobs}
                         assignJobsToUser={putUpdateJobsAdminUser}
                         onClose={handleModalClose}
                    />
               ),
          });
     };

     const Page = data ? data.Page : ({} as Pager);
     const TableSorter = data.TableSorter ? data.TableSorter : { Column: 'Id', Ascending: true } as Sorter;

     const CurrentPage = Page ? Page.CurrentPage : 0;
     const Items = data?.Page?.Items ? data.Page.Items : ([] as JobItemModel[]);
     const TotalItems = Page ? Page.TotalItems : 0;
     const ItemsPerPage = Page ? Page.ItemsPerPage : 0;
     const TotalPages = Page ? Page.TotalPages : 0;

     /** Custom Hooks */
     const { mutate: updateAdminUserData } = usePutUpdateAdminUserData();

     const showModule = () => {
          setModalContent({
               ...modalContent,
               show: true,
               header: 'Save Job Search',
               body: <SaveJobSearchModule func={saveAdminUserData} onClose={handleModalClose} />,
          });
     };

     const saveAdminUserData = (Name: string) => {
          if (savedSearches.find(x => x.SearchName.toUpperCase() === Name.toUpperCase())) {
               setAlert({
                    ...alert,
                    show: true,
                    header: "Saved Search Error",
                    body: `Unable to save the search as a saved search with the name ${Name} already exists. Please enter a different name and try again`
               });

               return;
          }

          savedSearches.push({
               VmName: 'AdminUsers.JobList',
               SearchName: Name,
               IsDefaultView: false,
               IsQuickSearch: true,
               SearchData: {
                    filter: data.Filter,
                    pager: {
                         CurrentPage: data.Page.CurrentPage,
                         TotalItems: data.Page.TotalItems,
                         ItemsPerPage: data.Page.ItemsPerPage,
                    },
                    sorter: {
                         Ascending: data.TableSorter?.Ascending,
                         Column: data.TableSorter?.Column,
                    },
               },
          } as SavedSearch);

          const adminData: UserData = { DefaultPage: '', SavedSearches: savedSearches };
          let adminUserData: AdminUserDataModel = { ID: 1, UserData: JSON.stringify(adminData) };

          updateAdminUserData(adminUserData);
          handleModalClose();
     };

     const CustomGridToolbar: React.FC = (): JSX.Element => {
          return (
               <Pagination
                    assignJobsToUser={assignJobsToUser}
                    adminUsers={adminUsersForDropdown}
                    itemsCount={TotalItems}
                    totalPages={TotalPages}
                    currentPage={CurrentPage}
                    pageSize={ItemsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    filter={<Filter
                         savedSearches={savedSearches}
                         selectedQuickSearch={selectedQuickSearch}
                         performQuickSearch={performQuickSearch}
                         showFilter={showFilter}
                         showSave={() => showModule()}
                         performClear={clearSearch}
                         runSavedSearch={(searchData: SearchData, quickSearch?: string) => {
                              let newValues = getJobFiltersFromSavedSearchModel(searchData.filter);
                              
                              handleSetPager(searchData.pager);
                              handleSetSorter(searchData.sorter);
                              handleSetFilter(newValues, quickSearch);
                         }}
                    />}
               />
          );
     };

     const CustomGridFooter: React.FC = (): JSX.Element => {
          return (
               <Pagination
                    itemsCount={TotalItems}
                    totalPages={TotalPages}
                    currentPage={CurrentPage}
                    pageSize={ItemsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
               />
          );
     };

     return (
          <>
               <Alert {...alert} />
               <div className='jobs-grid-container' style={{ height: '100%', width: '100%' }}>
                    <ModalDialog {...modalContent}></ModalDialog>
                    <div id='id_grid_jobs' style={{ display: 'flex', height: '100%', width: '100%' }}>
                         <DataGrid
                              autoHeight
                              checkboxSelection
                              className="jobGrid"
                              components={{ Toolbar: CustomGridToolbar, Footer: CustomGridFooter, }}
                              disableColumnMenu
                              disableExtendRowFullWidth
                              getRowId={(row: any) => row.Id}
                              rowHeight={41}
                              rows={Items}
                              selectionModel={selectedJobs}
                              sortingMode='server'
                              sortingOrder={['asc', 'desc']}
                              columns={getColumns()}
                              getRowClassName={(params) => getRowClassName(params)}
                              onSelectionModelChange={(newSelectionModel) => { setSelectedJobs(newSelectionModel); }}
                              onSortModelChange={handleSort}
                              sortModel={getSortModelFromSorter(TableSorter)}
                         />
                    </div>
               </div>
          </>
     );
};

export const SaveJobFooter: React.FC<any> = ({ onClose }): JSX.Element => {
     return (
          <>
               <Row>
                    <Col>
                         <Button label='Close' control={FormControlKeys.Button} onClick={onClose} />
                    </Col>
               </Row>
          </>
     );
};

export const getSortModelFromSorter = (sorter: Sorter): GridSortModel => {
     const sortModel: GridSortItem[] = [];
     sortModel.push({
          field: sorter.Column,
          sort: sorter.Ascending ? 'asc' : 'desc',
     });
     return sortModel;
};