import { useQuery, UseQueryResult } from 'react-query';
import agent from '../../../../../../Axios/Agent';
import { GetAdminRoleModelResponse } from '../../../../../../Models/Admin';

export const useGetAdminRole = (adminRoleID: number,
  options?: any
): UseQueryResult<GetAdminRoleModelResponse, any> => {

  const getAdminRole = () => {
    if(!adminRoleID) { // adminRoleID == 0 means new role creation
        const newRole: GetAdminRoleModelResponse = {
            Id:  0,
            RoleName: '',
            AdminRolePermissions: []
        };
        return newRole;
    }

    return agent.adminRole.getAdminRole(adminRoleID);
  }

  return useQuery(['GetAdminRole', adminRoleID], getAdminRole, {
    ...options,
  });
};
